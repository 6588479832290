button.btn {
    border-radius: $border-radius;

    &.btn-primary {
        background-color: $sapphire;
        border-color: $sapphire;

        &.btn-lg {
            padding: 15px 20px;
            font-size: $size-lg;
            font-weight: 500;
        }
    }
}

.button-default {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 36px;
    flex-shrink: 0;
}

.button-default[disabled] {
    border-radius: 2px;
    border: 1px solid $sapphire-200 !important;
    opacity: 0.64;
    background-color: $sapphire-200 !important;
    color: $sapphire-300 !important;
    font-weight: 300 !important;
}

.button-primary-blue-2 {
    @extend .button-default;
    color: $white;
    background-color: $sapphire;
    border: 1px solid $sapphire;
}

.button-primary-blue-2-rounded {
    border-radius: 1000px !important;
}

.button-primary-blue-2-outlined {
    @extend .button-default;
    border: 1px solid $sapphire;
    color: $sapphire;
}
.button-primary-grey {
    @extend .button-default;
    border: 1px solid $gray-400;
    background-color: $gray-100;
}
.button-grey {
    @extend .button-default;
    background-color: $gray-100 !important;
    border: 1px solid $gray-400;
    color: $black !important;
    border-radius: 2px;
}
.button-primary-green {
    @extend .button-default;
    background-color: $accent;
    color: $white;
    border: 1px solid $accent;
}
.button-primary-green-rounded {
    @extend .button-primary-green;
    border-radius: 50px;
    box-shadow: none;
}