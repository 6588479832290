.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.vertical-horizontal-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tinymce-textarea {
    height: 245px;
    width: 825px;
    margin-top: 35px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.175);
}