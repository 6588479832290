@import "./variables.css";

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #0973BA;
    border-color: #0973BA;
}

.default-button {
    border-radius: 15px !important;
    color: var(--white);
    box-shadow: 0px 6px 8px 0px var(--brand-black);
    background-color: var(--brand-green);
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.dropdown,
.dropup {
    position: relative;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
}

tr {
    border-style: hidden;
}

.react-bootstrap-table th .order > .dropdown > .caret {
    color: black;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-bootstrap-table th .order > .dropup > .caret {
    color: black;
    margin-left: 1px;
}

.tox-notifications-container {
    display: none;
}

/* Heading CSS */
.heading-blue-1 {
    font-size: var(--heading-size-2);
    color: var(--brand-dark-blue);
    font-weight: 500;
}

.heading-blue-2 {
    font-size: 14px;
    color: var(--brand-dark-blue);
    font-weight: 600;
}

/* Button CSS */

.button-primary-green {
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    text-align: center;
    border: none;
    padding: 5px 20px;
    min-width: 100px;
    cursor: pointer;
    line-height: normal;
    background-color: var(--brand-green) !important;
    color: var(--white);
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, .3);
}

.margin-right-10-px {
    margin-right: 10px !important;
}
/*-------- Scroll Bar CSS---Start-- */
/* width */
::-webkit-scrollbar {
    width: 8px;
    background-color: inherit;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f6f6f6;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--brand-light-blue);
    border-radius: 10px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--brand-light-blue);
    }
/*-------- Scroll Bar CSS---End---- */