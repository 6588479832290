.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    height: 85vh;

    .message {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: pre;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;

      svg {
        margin-bottom: 40px;
      }
    }
  }

}

@media (max-width: 500px) {
  .error-page {
    .error-page-container{
      height: 100%;
      margin-top: 20%;
    }
    .message {
      span {
        white-space: pre-line !important;
      }
    }
  }
}