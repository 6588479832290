@import "../../styles/variables.scss";

.dropoff-container {
  width: 100%;
  height: 100%;
  box-shadow: -1px 0px 0px 0px #C1CCD6 inset;

  .dropoff-card-title {
    color: white;
    text-align: center;
    padding: 15px 0;
    margin: 0;
    border-bottom: 2px solid white;
  }

  /* ShareFile Card Componenet CSS */

  .sharefilecard-container {
    display: flex;
    height: 100%;

    h5 {
      color: $black;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }


    .from-container {
      width: 466px;
      color: $black;
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      background-color: $navy-100;

      .invalid-feedback {
        position: absolute;
        bottom: -23px;
      }

      .to-form-container {
        margin-top: 25px;

        label {
          color: $black;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          margin-bottom: 4px;
        }

        .form-input-text {
          display: flex;
          padding: 6px 12px;
          align-items: center;
          flex: 1 0 0;
          border-radius: 4px;
          border: 1px solid $gray-400;
          background: $white;
          font-size: 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;

          &:focus {
            box-shadow: none;
          }
        }

        .error {
          border: 1px solid $error-red !important;
        }
      }

      .from-footer-links-container {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
      }
    }


    .to-container-more-files {
      @media (max-height: 680px) {
        .tox-tinymce {
          height: 220px !important;
        }
      }

    }

    .to-container {
      overflow-y: auto;
      flex: 1 1 70%;
      background-color: white;
      padding: 30px 50px;
      padding-bottom: 10px;

      hr {
        margin: 10px 0px !important;
      }

      .to-top-container {
        margin-top: 18px;

        .body-container {
          position: relative;
          padding-top: 10px;
        }
      }

      .droppoff__attachments {
        padding-top: 10px;
      }

      .input-group {
        font-size: 14px;

        .input-group-text {
          color: $gray-600;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          background-color: white;
          border: none;
          width: 14%;
          border-bottom: 1px solid #ced4da;
        }

        input {
          border: none;

          border-bottom: 1px solid #ced4da;

          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
            border-bottom: 1px solid #ced4da;
          }
        }
      }

    }

    .to-form-container>p {
      font-size: 12px;
      margin-top: -15px !important;
      margin-bottom: -10px !important;
      padding-left: 24px;
    }

    .to-top-container>p {
      font-size: 12px;
      margin: 6px 10px;
    }

    .empty-body-error-message {
      position: initial !important;
    }
  }

  .from-container .form-control.is-invalid {
    padding-right: 0;
    background-image: none;
  }

  .to-form-container>.input-container:not(:last-child) {
    margin-bottom: 23px;
  }

  .input-container {
    position: relative;
  }

  .error-message {
    position: absolute;
    top: 50%;
    margin: 0;
    margin-left: 20px;
    margin-top: 18px;
    color: #960000;
  }

  .redirect-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .redirect-text a {
    color: white;
  }

  .from-footer-links-container>a {
    color: white !important;
    margin-right: 20px;
  }

  .from-footer-links-container input {
    margin-right: 10px;
  }

  .to-recipent-container {
    width: 100%;

    .recipient__control {
      &:hover {
        border-color: #ced4da;
      }
    }

    .recipient__value-container {
      max-height: 61px;
      overflow-y: auto;
    }

    .recipient__input-container {
      font-size: 14px;
    }

    .recipient__multi-value {
      display: flex;
      padding: 1px 8px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #565A5E;
      background: $gray-100;
      height: 23px;
      gap: 5px;
    }

    .recipient__multi-value__remove {
      color: $gray-700;
      padding: 0px;
      width: 10px;
      height: 10px;

      &:hover {
        color: $gray-700;
        background-color: $gray-100;
      }

      svg {
        height: 10px;
        width: 10px;

        path {
          d: path("M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.70711 4L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L4 4.70711L1.35355 7.35355C1.15829 7.54882 0.841709 7.54882 0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645L3.29289 4L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z");
        }
      }
    }

    .recipient__multi-value__label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: $gray-700;
    }

    .recipient-multi-value-remove-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-700;
    }

  }

  .to-top-container .square-plus-icon {
    font-size: 20px;
    padding: 12px 5px 0px 5px;
    color: #88c656;
  }


  .to-top-container .input-group:first-child input {
    border-bottom: 1px solid #ced4da;
  }

  .to-top-container textarea {
    height: 20vh;
    border: none;
  }

  .to-container input:focus {
    outline: none !important;
  }

  #react-select-2-listbox {
    z-index: 1000;
  }

  .to-top-container .message-label {
    border: 0px;
  }

  .attach-button {
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 2px;
    width: 150px;
    height: 38px;
  }

  .drag-drop-container {
    display: flex;
    text-align: center;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    border-radius: 2px;
    border: 1px dashed $sapphire;
    background: rgba(230, 235, 240, 0.20);

    .drag-drop-helper-text {
      color: $gray-600;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .button-primary-blue-2-rounded {
      margin-top: 20px;
    }
  }

  .drag-drop-container.hide {
    display: none;
  }


  .to-bottom-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    .button-primary-blue-2 {
      width: 148px;
      border-radius: 2px;
      height: 38px;
    }
  }

  .to-bottom-container i {
    margin-right: 5px;
  }

  .to-bottom-container label {
    margin-right: 10px;
  }

  #uploadFile {
    display: none;
  }

  .drag-drop-container .upload-icon {
    font-size: 35px;
    padding: 10px;
    color: #0973ba;
  }

  .Browse-button {
    cursor: pointer;
  }

  .droppoff__attachments {
    padding-top: 5px;
  }

  .attachments {
    display: flex;
    max-height: 65px;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 5px;
  }

  .attachments-tab .divDocumentUpload {
    border: 4px dashed #bababa;
    padding: 8px;
    background: #fafafa;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .attachments-tab .divDocumentUpload img {
    display: block;
    margin: 0 auto;
  }

  .attachments-tab .title {
    padding: 1px 10px;
    background: #fff;
  }

  .attachments-tab .panel-default .react-bs-table * {
    border: none;
  }

  .attachments-tab .panel-default .react-bs-table {
    border: none;
    color: #222;
    font-weight: bold;
  }

  .attachments-tab .dz-default.dz-message {
    display: none;
  }

  .attachments-tab .btn-success {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .attachments-tab .btn-danger {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
  }

  .attachment-card {
    display: flex;
    position: relative;
    min-width: 120px;
    max-width: 245px;
    height: 30px;
    padding: 1px 5px 1px 8px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 4px;
    background: $navy-100;
  }

  .attachment-icon {
    position: relative;
    width: 45px;
  }

  .attachment-icon img {
    top: -0;
    left: -8px;
    position: relative;
  }

  .upload-progress-bar {
    height: 4px;
    position: absolute;
    background: #88c656;
    bottom: 0;
    -webkit-transition: all 600ms;
    -moz-transition: all 600ms;
    -ms-transition: all 600ms;
    -o-transition: all 600ms;
    transition: all 600ms;
  }

  .attachment-remove {
    position: absolute;
    right: 10px;
    width: 15px;
    height: 28px;
    text-align: center;
    color: $gray-700;
    cursor: pointer;
  }

  .attachment-detail {
    width: 80%;
    color: $gray-700;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 220px !important;
    white-space: pre;
  }

}

.share-file-card-wrapper {
  height: 100%;
}

.access-code-modal-container {
  .modal-content {
    border-radius: 10px;
  }

  .modal-title {
    width: 100%;
    text-align: center;
  }

  .modal-content {
    width: 600px;
  }

  .card-title {
    color: $black;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .card-sub-title {
    color: $black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 9px;
  }

  .button-primary-blue-2 {
    display: flex;
    width: 399.334px;
    height: 56px;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    margin-top: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  .spam-text {
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 10px;
  }

  .resend-btn {
    @extend .spam-text;
    text-decoration: underline;
    margin-top: 0px;
  }

  .close-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    opacity: 0.5;
    background-color: rgba(255, 255, 255, 0.00);
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .otp-expiry-text {
    color: $error-red;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 30px;
  }

  .invalid-feedback {
    position: absolute;
    margin-top: 7px;
    bottom: -30px;
  }
}

.dropoff-mobile-layout {
  width: 100%;
  height: calc(100vh - 75px);
  display: flex;
  overflow: hidden;

  .drop-off-mobile-view {
    width: 100%;

    .drop-Off-wizard-container {
      background-color: $sapphire-600;
      padding: 10px 10px 5px 10px;
    }

    .header-right-container {
      padding-right: 20px !important;
    }

    .back-arrow-header-btn {
      position: absolute;
      left: 20px;
      top: 15px;
      width: 30px;
    }

    .from-container {
      width: auto !important;
      margin: 24px;
      padding: 24px 23px 63px 23px !important;
      background-color: $sapphire-100;

      h5 {
        color: #212529;
        font-family: Mulish;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .from-sub-text {
        color: #565A5E;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .input-container {
        margin-bottom: 15px;

        label {
          color: $black;
          font-family: Mulish;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }


      .form-control {
        &.error {
          border-color: $error-red !important;
          border-width: 1px !important;
      }
        &::placeholder {
          font-style: normal; // Ensures placeholder text is not italic
          color: $gray-600;
        }
      }

      .remember-me-text {
        /* Body/Regular */
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .to-form-container {
        margin-top: 20px;

        .form-control {
          display: flex;
          height: 48px;
          align-items: center;
          flex-shrink: 0;
          font-size: 16px;
          padding: 8px 16px;
        }
      }
    }

    // Screen 2 CSS Start

    .to-container {
      height: calc(100vh - 140px);
      padding: 24px;
      padding-top: 10px;


      .input-group {
        color: $gray-600;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 10px;

        .form-control {
          display: flex;
          padding: 6px 12px;
          align-items: center;
          flex: 1 0 0;
          padding-left: 0px;
          background: $white;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          border: 0px;
          border-bottom: 1px solid $gray-200;

          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            font-style: normal; // Ensures placeholder text is not italic
          }
        }
      }

      .to-recipient-group {
        display: flex;
        flex-wrap: nowrap;
        border-bottom: 1px solid $gray-200;

        .form-label {
          padding-top: 5px;
        }

        .more-recipients-btn {
          font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          position: absolute;
          right: 0;
          top: 6px;
          color: $navy;
        }

        .to-recipent-container {
          margin-left: 5px !important;

          .recipient__value-container {
            max-height: 30px;
            overflow-y: hidden;
            scroll-behavior: smooth;
          }

          .recipient__control {
            border-bottom: 0px;
            border: none;
            max-width: calc(100vw - 125px);
          }

          .recipient__value-container {
            max-width: calc(100vw - 125px);

            .recipient__multi-value {
              max-width: 230px;
            }
          }
        }

      }

      .tox-tinymce {
        height: calc(100vh - 500px) !important;
      }

      .max-message-container {
        .tox-tinymce {
          height: 400px !important;
        }
      }

      .max-recipient-container {
        .recipient__value-container {
          max-height: 117px !important;
          overflow-y: auto !important;
        }


      }

      .max-attachment-container {
        .attachments {
          max-height: 113px;
        }
      }

      .to-bottom-container {
        bottom: 25px;
        width: calc(100vw - 49px);

        .button-primary-blue-2 {
          width: 148px;
        }
        .button-primary-blue-2-outlined {
          width: 148px;
        }
      }

      .droppoff__attachments {
        .attachment-card {
          height: 33px;
          max-width: calc(100% - 0px);
          width: calc(100% - 0px);

        }

        .attachment-detail {
          color: $sapphire;
          font-family: Mulish;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          max-width: calc(100% - 41%) !important;

          svg {
            margin-right: 8px;
          }
        }

        .attachment-remove {
          right: 10px;
          width: 110px;

          .attachment-remove-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            width: 100%;
          }

          .attachment-size {}

          .attachment-remove-icon {
            position: absolute;
            right: 0px;
            display: contents;
          }
        }
      }

    }

    // Screen 2 CSS End

    // Screen 3 Authentication CSS Start

    .mobile-otp-auth-container {
      padding: 60px 47px 20px 47px;

      h2 {
        color: $black;
        font-family: Mulish;
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
        margin-top: 34px;
        white-space: pre-line;
      }

      .mobile-otp-helper-text {
        color: $gray-600;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 24px */
        margin-top: 24px;
        margin-bottom: 30px;
      }

      .mobile-otp-expires-text {
        color: $error-red;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }

      .company-logo {
        height: 45px;
        object-fit: contain;
        max-width: 240px;
      }

      .mobile-otp-junk-text {
        color: $gray-600;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        margin-bottom: 0px;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }

      .mobile-otp-contact-us {
        color: $navy;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-left: 3px;
      }

      .button-primary-blue-2 {
        display: flex;
        width: 296px;
        height: 48px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #FFF;
        font-family: Mulish;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        border-radius: 2px;
        margin-bottom: 20px;

        &[disabled] {
          background-color: $gray-100 !important; 
          border-color: $gray-200 !important;
          color: $gray-200 !important;
        }
      }

      .mobile-otp-resend-code-btn {
        color: $black;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration-line: underline;
        margin-bottom: 20px;
      }

      .otp-input-control-wrapper {
        margin-bottom: 30px;
        align-items: flex-start;

        input {
          color: $black;
          text-align: center;
          font-family: Mulish;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          height: 34px;
          width: 30px;
          margin-right: 10px;
        }

        .invalid-feedback {
          white-space: pre-line;
          width: 100% !important;
          text-align: left;
        }
      }
    }

    // Screen 3 Authentication CSS End
    .footer-btn-container {
      bottom: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .step-1-send {
        display: flex;
        width: 296px;
        height: 48px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #FFF;
        font-family: Mulish;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        border-radius: 2px;

      }
    }
  }
}

@media (max-width: 450px) {
  .dropoff-mobile-layout {
    height: calc(100vh - 90px);

    .droppoff__attachments {
      .attachment-detail {
        max-width: 170px !important;
      }
    }


    .otp-input-control-wrapper {
      input {
        width: 28px !important;
      }

    }

    .mobile-otp-junk-text {
      white-space: pre-line !important;
    }

  }
}

.iphone-devices {
  .to-bottom-container {
    bottom: 30px !important;
  }
  .footer-btn-container {
    bottom: 50px !important;
  }


}

@media (max-width: 347px) {
  .recipient__multi-value {
    max-width: 180px !important;
  }
  .mobile-otp-auth-container {

      .button-primary-blue-2 {
        width: 270px !important;
      }
      .otp-input-control-wrapper {
        input {
          width: 25px !important;
        }
  
      }
    }
}