// FORM CONTROL

input.form-control {
    border-radius: $border-radius - 2;
    border: 1px solid $gray-400;

    &::placeholder {
        color: $gray-200;
        font-style: italic;
    }

    &.form-control-lg {
        height: 48px;
    }
}

// OTP INPUT CONTROL

.otp-input-control-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    .input-container {
        display: flex;
        flex-direction: row;
    }

    .invalid-feedback {
        bottom: -25px !important;
    }
    input {
        width: 34px;
        border: 1px solid $gray;
        background-color: $bg-white;
        border-radius: $border-radius - 2;
        font-size: $size-md * 2;
        padding: 0px;

        &:focus-visible,
        &:focus,
        &:focus-within {
            outline: none;
        }

        &:not(:last-child) {
            margin-right: 18px;
        }
    }

    @media (min-width: 1020px) {
       input {
            height: 41px;
       }
    }
    &.error {
        input {
            border-color: $error-red;
        }
    }

    .icon {
        color: $error-red;
        margin-left: 10px;
    }

    .error-text {
        width: 100%;
        font-size: $size-sm;
        color: $error-red;
        margin-top: 10px;
    }

    @media (min-width: 400px) and (max-width: 600px) {
        .invalid-feedback {
            text-align: center;
        }
    }

    @media (max-width: 380px) {
        input {
            width: 25px !important;
        }
    }
}

.invalid-feedback {
    display: flex !important;
    word-wrap: break-word;
    color: $error-red;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;
}

// Creatable Select 
.recipient__menu {
    z-index: 999 !important;
}