:root {
  /* ---Primary colors ----*/
  --brand-dark-blue: #05386b;
  --brand-light-blue: #0973ba;
  --brand-green: #88c656;
  --brand-dark-blue-10per: rgba(5, 56, 107,0.1);
  /*---- Secondary colors--- */
  --brand-grey:  rgba(217, 217, 214,0.35);
  --brand-dark-grey: #ccc;
  --brand-black:rgb(16, 24, 32);
  --brand-black-50per:rgba(16, 24, 32,0.50);
  --brand-black-20per:rgba(16, 24, 32,0.20);
  --non-branded-light-blue_hover: #0d6efd40;
  --white: #fff;

  /* Font  */
  --font-family: 'Mulish', sans-serif;
  --base-font-size: 12px;
  --font-size-md: 14px;
  --heading-size-1: 18px;
  --heading-size-2: 16px;

  /* Devices */
  --mobile: 425px;
  --tablet: 768px;
  --laptop: 1024px;
  --desktop: 1440px

}