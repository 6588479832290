.file-delete-modal {
  .modal-dialog {
    max-width: 500px;

    .modal-content {
      max-width: 500px;
    }
    .button-primary-blue-2 {
      border-radius: 2px;
      border: 1px solid $error-600;
      background: $error-600;
      width: 125px;
    }

  }
}

.upload-done-confirm-modal {
  .modal-dialog {
    max-width: 520px;

    .modal-content {
      max-width: 520px;
    }
  }

  .modal-body {
    white-space: pre-line;
  }
}

.send-without-attachment-confirmation-modal {
  .modal-dialog {
    width: 500px;
  }
}

@media (max-width: 600px) {
  .send-without-attachment-confirmation-modal {
    .modal-dialog {
      width: 95% !important;
      margin: auto;
    }
  }
}
// Custom modal styles Start
.custom-modal-container {
  .modal-content {
    background-color: transparent;
    font-size: 16px;
    border-radius: 4px !important;
    border: none !important;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
    margin: 0px auto;
  }

  .body-bottom-radius {
    border-bottom-left-radius: calc(0.5rem - 1px) !important;
    border-bottom-right-radius: calc(0.5rem - 1px) !important; 
  }
  
  .modal-dialog {
    max-width: 700px;
  }

  .modal-header {
    background-color: $navy;
    padding: 16px;
    border: none;
    border-bottom: 1px solid $navy-500;
  }

  .modal-footer {
    background-color: white;
    border-top-color: #dee2e6;
    display: flex;

    .modal-button-container {
      display: flex;
      justify-content: flex-end;
    }

    button {
      border-radius: 2px;
      margin-left: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      min-width: 100px;
      padding: 6px 12px;
      text-align: center;
      height: 34px;
    }
  }

  .additional-action {
    font-size: 16px;
  }

  .modal-title {
    color: white;
    font-size: 20px;
    line-height: 120%;
    max-width: 96%;
  }

  .modal-body {
    max-height: calc(100vh - 185px);
    overflow: auto;
    background-color: white;
  }

  // media queries for mobile
  @media (max-width: 768px) {
    .modal-dialog {
      min-width: 300px;
      max-height: 400px !important;
      margin-top: 55px;
    }
  }
}

.confirm-modal {
  .modal-header {
    background-color: white;
    padding: 16px;
    border-bottom-color: #dee2e6;

    .modal-title {
      color: $black;
      font-weight: 700;
    }

    button {
      color: $gray-400;
    }
  }
}

// Custom modal styles End

.drawer-right-modal {
  display: flex !important;
  flex-direction: row-reverse;
  overflow-y: hidden;

  .modal-dialog {
      height: 688px;
      margin: 0px;
      transform: translate(100%, 15%) !important;
  }

  .modal-content {
      width: 314px;
      height: 688px;
  }

  .modal-title {
      color: $black;
  }

  
  .modal-header {
      padding: 10px 20px 10px 20px;
      background-color: white;
      padding: 16px;
      border: 0px;
      border-bottom-color: #fff !important;

      svg {
        margin-top: 6px;
      }
      .modal-title {
          color: $black;
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          gap: 10px;
          margin-left: 10px;
      }
  }
  .modal-body {
    padding-top: 0px;
  }
  .modal-footer {
      button {
          border-radius: 2px;
          font-size: 16px;
      }
  }
  .modal-content {
      border-style: none;
      box-shadow: none;
  }

  button {
      padding: 6px 15px 6px 15px;
  }

  &.show {
      pointer-events: auto;

      * {
          pointer-events: auto;
      }

      .modal-dialog {
          transform: translate(0, 15%) !important;
      }
  }

}
