.primary-alert {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  border-left: 5px solid $sapphire;
  background: $sapphire-100;
  color: $black;
  min-height: 50px;
  margin-bottom: 20px;

  svg {
    margin-right: 10px;
  }
}