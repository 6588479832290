// COLORS

$sapphire: #0973BA;
$sapphire-100: #E6F1F8;
$sapphire-200: #B5D5EA;
$sapphire-300: #6BABD6;
$sapphire-600: #075C95;
$navy: #05386b;
$navy-100: #E6EBF0;
$theme-denim: #13294B;
$black: #212529;
$white: #FFF;
$gray: #6B7075;
$gray-100: #F0F1F1;
$gray-200: #C4C6C8;
$gray-300: #a6a9ac;
$gray-400:#898D91;
$gray-500: #6B7075;
$gray-600: #565A5E;
$gray-700: #404346;
$gray-900: #151617;
$error-100: #FAEDEC;
$error-600: #b8433c;
$success-100: #E9F3ED;
$success-500: #1F8747;
$error-red: #CC4A43;
$theme-warning: #D69F38;
$warning-100:#fbf5eb;
$bg-white: #F5F6FB;
$shamrock: #669440;
$black-100: rgba(0, 0, 0, .1);
$accent: #88C656;
$shamrock-600: #5C8001;

// SIZES
$size-sm: 14px;
$size-md: 16px;
$size-lg: 18px;

$border-radius: 4px;

$font-family: 'Mulish', sans-serif;

// Color Codes
$navy 		: #05386b;
$navy-100 	: #e6ebf0;
$navy-200 	: #b4c3d3;
$navy-300 	: #9bafc4;
$navy-400 	: #6988a6;
$navy-500 	: #05386b;
$navy-600 	: #053260;
$navy-700 	: #04274b;
$navy-800 	: #032240;
$navy-900 	: #011120;