@import "../../styles/variables";

.access-card-container {
  display: flex;
  justify-content: center;
  max-width: 100%;

  .message-icon-container {
    cursor: pointer;
    display: inline-flex;
    padding: 28px 22px 22px 22px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    border-radius: 4px;
    background: $white;

    box-shadow: 4px 4px 8px 0px rgba(151, 151, 151, 0.25), 0px 0px 6px 0px rgba(151, 151, 151, 0.25);


    .view-cpa-note-text {
      color: $black;
      text-align: center;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 21px */
    }
  }

  .access-link-header {
    display: flex;
    width: 100%;
    padding: 0% 10%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .card-title {
    color: $black;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: 25px;
    margin-bottom: 15px;

  }

  .card-sub-title {
    color: $black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    white-space: pre-line;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .primary-input {
    width: 400px;
    margin-top: 25px;
    text-align: left;
    margin-bottom: 5px;
  }

  .button-primary-blue-2 {
    width: 400px;
    height: 56px;
    color: $white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.8px;
    margin-top: 42px;
    border-radius: 104px;
  }

  [disabled].button-primary-blue-2 {
    border-radius: 104px
  }

  .email-popup-container {
    text-align: center;
    border-radius: 15px;
    border: none;
    margin-top: 30px;

    .invalid-feedback {
      width: 400px;
      position: absolute;
      bottom: -20px;
    }

    p {
      white-space: pre-line;
    }
  }

  .validation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .invalid-feedback {
      width: 400px;
      position: absolute;
      bottom: -20px;
    }

    .card-title {
      margin-top: 15px;
    }

    .question-text {
      color: $black;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-top: 19px;
      margin-bottom: 5px;
    }

    .otp-expiry-text {
      color: $error-red;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 10px;
    }

    .button-primary-blue-2 {
      margin-top: 30px;
      background-color: $sapphire;
      border-color: $sapphire;
    }

    .card-sub-title {
      margin-bottom: 0px;
    }

    .spam-text {
      color: $black;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 10px;
    }

    .bottom-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .resend-btn {
      @extend .spam-text;
      text-decoration: underline;
      margin-top: 0px;
    }
  }


  .file-download-container,
  .upload-page-container {
    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between
    }

    .files-text {
      color: $black;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;

      .files-count {
        color: $black;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

      }
    }

    .table-inline-actions {
      display: flex;
    }

    .table-inline-icon-container {
      display: flex;
      align-items: center;
      min-width: 100px;
      color: $navy;

      svg {
        margin-right: 10px;
      }

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

    }

    .download-btn-div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 35px;

      .button-primary-blue-2 {
        display: flex;
        width: 150px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 2px;
        height: 38px;
        font-weight: 400;
        font-size: 16px;
        font-style: normal;
        margin-top: 0px;
        background-color: $sapphire !important;
        border-color: $sapphire !important;
      }
    }

    .fixed-height-wrapper {
      max-height: calc(100vh - 330px);
      overflow-y: auto;
      overflow-x: hidden;

      .react-bootstrap-table {
        max-height: calc(100vh - 321px);
      }
    }

    .due-date-table-height {
      max-height: calc(100vh - 365px);
    }
  }

  .file-download-container {
    padding: 5px 20px;

    .no-files-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $navy-100;
      margin-top: 10px;
      height: calc(100vh - 340px);
      min-width: 320px;
    }

    .sender-text {
      font-weight: 600;
      color: var(--brand-light-blue);
      font-size: 15px;
    }

    .file-download-header-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .button-primary-blue-2 {
      margin-top: 15px;
    }

    .file-download-link {
      color: $black;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 6px;
      }

      .file-name {
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 90%;
      }
    }

    .file-download-link:hover {
      text-Decoration: underline;
    }

    .sender-message,
    .file-list-placeholder {
      font-size: 12px;
      color: grey;
    }

    hr {
      margin: 5px 0px;
    }

    .file-download-table {

      table-layout: fixed !important;

      thead {
        position: sticky;
        top: 0;
      }

      th {
        color: $black;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }

      tr {
        color: $black !important;
        font-size: 16px !important;
        font-style: normal;
        line-height: 24px;
        display: table-row;

        td:first-child {
          padding-left: 0px;
        }

        td:nth-child(2) {
          span {
            color: $gray-400;
          }
        }
      }
    }

    .file-download-table th:nth-child(3) {
      width: 55px;
    }

    .file-download-table td:nth-child(3) {
      text-align: center;
      cursor: pointer;
    }
  }

  .upload-page-container {
    width: 80%;

    .access-link-header {
      width: 80%;
      align-items: center;
      justify-content: center;
    }

    .upload-container {
      padding: 5px;

      .file-name-with-status-cell {
        display: flex;
        align-items: center;
        max-width: 95%;
      }


      .expand-cell {
        text-align: right;
      }

      .expand-text {
        color: $navy;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration-line: underline;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      .file-upload-table {
        table-layout: fixed !important;

        .file-upload-button {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }

      .file-display-table-container {
        max-height: 178px;
        overflow: scroll;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
      }

      .file-display-table {
        width: 100%;
        table-layout: fixed !important;

        .file-name {
          display: block;
          max-width: 90%;
        }

        thead {
          position: initial;
        }

        tr {
          th:first-child {
            padding-left: 100px;
          }

          td {
            padding: 9px 0px;
          }

          td:first-child {
            padding-left: 100px;
            font-weight: 700;
          }
        }

        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #F0F1F1;
            }
          }
        }

      }

      .expand-collapse-icon {
        cursor: pointer;
        font-size: 20px;
        color: $navy;
        margin-right: 20px;
      }

      .due-date-wrapper {
        margin: 10px 0px;

        .due-on-text {
          color: $black;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
        }

        .due-date-text {
          color: $black;
          font-size: 14px;
          font-weight: 400;
        }
      }

    }
  }
}

.hide {
  display: none;
}

/* mobile Css */

@media (max-width: 425px) {

  .access-card-container {
    .access-link-container {
      width: 90% !important;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .access-link-header {
      display: flex;
      flex-direction: row;
      padding: 0px;
    }

    .primary-input {
      width: 320px;
    }

    .invalid-feedback {
      width: 320px !important;
      text-align: center;
    }

    .button-primary-blue-2 {
      width: 320px;
    }

    .email-popup-container {
      height: calc(100vh - 191px);
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .validation-container {
      height: calc(100vh - 24vh);
      padding-top: 10px;
    }

   
  }

}

/* tablet Css */

@media (max-width: 768px) {

  .access-link-container {
    width: 90%;
  }

  .upload-page-container {
    width: 98% !important;

    .file-upload-table {
      .table-inline-icon-container {
        min-width: 40px;
      }

      .table-icon-inline-text {
        display: none;
      }

    }
  }

  .access-link-header {
    padding: 20px;
  }
}

@media (max-height: 480px) {
  .access-link-container {
    max-height: 530px !important;
  }

  .fixed-height-wrapper {
    min-height: 280px !important;
  }

  .download-btn-div {
    margin-bottom: 25px !important;
  }
}

/* Laptop CSS */
@media (min-width: 768px) {
  .access-card-container {
    height: calc(100% - 82px);
  }

  .access-link-content {
    width: 80%;
  }

  .upload-page-container {
    .access-link-content {
      width: 100% !important;
    }
  }
}

@media (max-width: 1300px) {
  .access-link-container {
    width: 90%;
  }

  .upload-page-container {
    width: 93% !important;
  }

  .access-link-header {
    width: 90% !important;
  }
}

/* Desktop CSS */
@media (min-width: 1440px) {
  .access-card-container {
    height: calc(100% - 98px);
  }
}

.access-link-layout {
  width: 100%;

  .header-2 {
    padding: 10px 0px !important;
    height: 65px;
  }

  .header-step-container {
    margin: 0 auto;
    margin-bottom: 10px;

    .horizontal-line {
      margin-bottom: 5px;
    }

    .step-wrapper {
      color: $white;

      .step {
        color: $white;
        border-color: $white;
        font-family: Mulish;
        font-size: 10px;
        font-weight: 700;
        line-height: 15px;
        text-align: center;
        min-width: 20px;
        height: 20px;
      }

      .step-name {
        position: absolute;
        margin-top: 45px;
        font-family: Mulish;
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;

      }
    }
  }
}

.mobile-access-link-layout {
  .primary-input {
    width: 296px !important;
  }

  .button-primary-blue-2 {
    width: 296px !important;
  }

  .invalid-feedback {
    width: 296px !important;
  }

  .download-header {
    .step-wrapper:last-child {
      .step-name {
        padding-left: 10px;
      }
    }
  }
}

.welcome-screen-container {
  width: 100%;
  height: calc(100vh - 182px);
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome-screen-image {
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .welcome-screen-content {
    width: 100%;
    padding: 0 30px;

    h2 {
      font-family: Mulish;
      font-size: 28px;
      font-weight: 300;
      line-height: 33.6px;
      text-align: left;
      color: $sapphire;

    }

    p {
      font-family: Mulish;
      font-size: 28px;
      font-weight: 300;
      line-height: 33.6px;
      text-align: left;

    }

    .btn-container {
      position: absolute;
      bottom: 60px;
      width: calc(100% - 60px);
      display: flex;
      justify-content: center;
    }

    .button-primary-green-rounded {
      margin-top: 20px;
      width: 100%;
      max-width: 300px;
      height: 48px;
      color: $black;
      font-family: Mulish;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;

    }
  }

  .header-step-container {
    width: 225px !important;
    padding-top: 20px;
    margin: 0 auto;

    .horizontal-line {
      margin-bottom: 5px;
      width: 55px !important;
    }

    .step-wrapper {
      color: $sapphire-600;

      .step {
        color: $sapphire-600;
        border-color: $sapphire-600;
      }

      .step-name {
        position: absolute;
        margin-top: 60px;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;

      }
    }
  }
}

.mobile-verify-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left !important;

  .invalid-feedback {
    bottom: -39px !important;
    text-align: left;
  }

  .card-title {
    color: $black;
    font-family: Mulish;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }

  p {
    color: $gray-600;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .primary-input {
    padding: 8px 16px;
    height: 46px;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &::placeholder {
      font-style: normal; // Ensures placeholder text is not italic
      color: $gray-600;
    }
  }

  button {
    border-radius: 2px !important;
    height: 48px !important;

    &[disabled] {
      background-color: $gray-100 !important;
      border-color: $gray-200 !important;
      color: $gray-200 !important;
    }
  }

}

.mobile-auth-container {
  display: flex !important;
  align-items: flex-start !important;
  text-align: left !important;

  .invalid-feedback {
    bottom: -35px !important;
    text-align: left;
  }

  .question-text {
    width: 296px;
    text-align: left !important;
  }
  .card-title {
    color: $black;
    font-family: Mulish;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    width: 296px;
  }

  .helper-text-container {
    width: 296px;
  }

  .card-sub-title {
    color: $gray-600;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 296px;
  }

  .question-text {
    color: $black;
    font-family: Mulish;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .primary-input {
    height: 48px;
  }

  button {
    border-radius: 2px !important;
    margin-top: 35px !important;
    height: 48px !important;

    &[disabled] {
      background-color: $gray-100 !important;
      border-color: $gray-200 !important;
      color: $gray-200 !important;
    }
  }
}

.mobile-auth-otp-container {
  padding: 0px 30px;

  .top-container {
    padding: 0px 30px;

    .card-sub-title {
      text-align: left !important;
    }
  }

  .otp-input-control-wrapper {
    margin: 20px 30px;
    max-width: 296 !important;
    input {
      width: 25px !important;
      height: 41px;
    }

    input:not(:last-child) {
      margin-right: 14px;
    }

    .invalid-feedback {
      bottom: -41px !important;
      text-align: left;
    }
  }

  .bottom-container {
    padding: 10px 30px 5px 30px;

    .spam-text {
      text-align: left !important;
      color: $gray-600;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .resend-btn {
      width: 150px;
      text-align: left;
      padding: 0;
      color: $black;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-top: -10px !important;
      text-decoration-line: underline;
    }

    .dropdown-toggle {
      margin-top: 0px !important;
    }
    .mobile-otp-junk-text {
      margin-top: 25px;
      color: $gray-600;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 21px */
      margin-bottom: 0px;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    .mobile-otp-contact-us {
      color: $navy;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-left: 3px;
    }
  }
}

.company-logo {
  height: 45px;
  object-fit: contain;
  max-width: 240px;
}

.mobile-download-container,
.mobile-upload-container {
  padding: 5px 10px 20px 10px !important;

  .tooltip-inner {
    max-width: 150px !important;
  }

  .hide-download-div {
    display: none !important;
  }
  .download-btn-div {
    margin-bottom: 25px !important;
    justify-content: center !important;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);

    .button-primary-blue-2 {
      width: 296px !important;
      height: 48px !important;
      color: $white;
      font-family: Mulish;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      background-color: $sapphire !important;
      border-color: $sapphire !important;
    }

    .button-primary-green {
      width: 296px !important;
      height: 48px !important;
      color: $white;
      font-family: Mulish;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      background-color: $shamrock-600 !important;
      border-color: $shamrock-600 !important;
      border-radius: 2px !important;
      box-shadow: none;
    }
  }

  .access-card-container {
    .primary-modal {
      .modal-body {
        max-height: 500px !important;
      }
    }

    .input-container {
      align-items: center;
      padding-bottom: 20px;
    }

    .button-primary-blue-2 {
      margin-top: 30px;

      &[disabled] {
        background-color: $gray-100 !important;
        border-color: $gray-200 !important;
        color: $gray-200 !important;
      }
    }

    .otp-input-control-wrapper input:not(:last-child) {
      margin-right: 5px;
    }

    .spam-text {
      width: 320px;
      text-align: center;
    }
  }

  .no-files-container {
    align-items: start !important;
    background-color: $white !important;
    
    span {
      font-family: Mulish;
      font-size: 16px !important;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: $black !important;
    }
  }
  .message-icon-container.disabled {
    opacity: 1;

    svg {
      opacity: 0.4;
    }

    span {
      opacity: 0.4;
    }
  }
  .invalid-feedback {
    width: 370px !important;
    text-align: center;
  }

  .table-icon-inline-text {
    display: none !important;
  }

  .file-upload-table {
    padding-top: 10px;

    .inline-icon-container {
      min-width: 40px !important;
    }

    .row-expansion-style {
      padding: 0px;
    }

    .expand-collapse-icon.disabled {
      opacity: 1;
      color: $gray-200 !important;
    }
    .table-inline-icon-container {
      padding-left: 15px;
      padding-top: 5px;

      &:nth-child(2) {
        padding-left: 10px;
      }
    }

    .expand-cell {
      width: 30% !important;
    }

    .expand-cell-header {
      width: 30% !important;
    }
    tr {
      display: table-row !important;

      th {
        &:nth-child(2) {
          padding-left: 10px !important;
        }
        &:nth-child(6) {
          padding-left: 20px !important;
        }
      }
      td {
        padding: 14px 5px !important;

        &:nth-child(2) {
          padding-left: 10px !important;
        }
      }

    }

    .file-display-table {
      tr {
        th:nth-child(1) {
          padding-left: 10px !important;
        }

        td:first-child {
          padding-left: 10px !important;
        }

        td:nth-child(3) {
          display: flex !important;
          padding-left: 8% !important;
        }
      }
    }

  }

  .file-download-link {
    .file-name {
      width: 100%;
    }
  }

  .file-download-table {

    .table-icon-inline-text {
      display: none;
    }

    tr {
      display: table-row !important;

      td {
        padding: 14px 0px !important;
      }

      td:nth-child(2) {
        span {
          color: $gray-600 !important;
        }
      }
    }

    
    .file-size {
      font-family: Mulish;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $gray-600;
    }
    .table-inline-icon-container {
      justify-content: center;
      min-width: 45px;
    }
  }

  .file-download-container {}

  .question-text {
    word-break: break-word;
  }

  .fixed-height-wrapper {
    max-height: calc(100vh - 330px) !important;
    margin-top: 10px;

    .react-bootstrap-table {
      max-height: calc(100vh - 270px) !important;
    }
  }
}

.mobile-upload-container {
  .fixed-height-wrapper {
    max-height: calc(100vh - 350px) !important;
  }
}

.iphone-devices {
  .mobile-upload-container {
    .fixed-height-wrapper {
      max-height: calc(100vh - 395px) !important;
    }
  }

  .mobile-auth-otp-container {
    @media (max-height: 667px) { 
    .mobile-otp-junk-text {
      margin-top: 10px;
      margin-bottom: 15px;
    }
    } 
    .input-container {
      input {
        height: 50px !important;
      }
    } 
  }

}

// Samsung Z Fold responsive CSS

@media (max-width: 355px) {

  .access-code-page-container {
    height: calc(100vh - 150px) !important;
    overflow: hidden;
  }

  .file-upload-table {
     thead {
      tr {
        th {
          &:nth-child(2) {
            width: 25% !important;
          }
          &:nth-child(3) {
            width: 35% !important;
          }
        }
      }
     }
  }
}