header.layout-header {
    padding: 10px 20px;
    border-bottom: .5px solid $black-100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid $theme-denim;
    background: $theme-denim;

    .header-logo-name-container,
    .header-right-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .separator-line {
        height: 25px;
        border-right: 1px solid $gray-200;
        margin-left: 10px;
    }

    .company-name {
        font-size: 16px;
        font-weight: 400;
        padding-left: 15px;
        margin: 0;
        color: $white;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 70%;        
    }

    .white-company-logo {
        height: 20px;
        object-fit: contain;
        max-width: 110px;
        margin-left: 20px;
    }
    .header-home-info {
        color: $white;
        float: right;
        cursor: pointer;
        margin-right: 30px;
    }


    .header-home-info a {
        color: $white;
        display: flex;
        align-items: center;
    }

    .header-home-info a span {
        padding-top: 3px;
        margin-left: 10px;
        font-weight: 500;
        line-height: 1.6;
    }

    .header-home-info:hover {
        text-decoration: underline;
    }


    .profile-icon {
        background-color: $gray-600;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $sapphire;
        }
    }


}

.profile-dropdown {
    .dropdown-toggle {
        box-shadow: none !important;
        border: none;
        background: transparent !important;
        padding: 0;

        &::after {
            display: none;
        }

        &>svg:hover {
            rect {
                fill: $shamrock !important;
            }
        }
    }

    .profile-contact-us {
        color: $navy;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }

    .dropdown-menu {
        border-radius: 4px;
        border: 1px solid $black-100;
        width: 100px;
        padding-top: 8px;
        padding-bottom: 8px;

        .dropdown-item {
            font-size: 16px;
            color: $gray-900;
            font-weight: 500;
            padding: 4px 16px;

            &:hover {
                background-color: $gray-200;
            }

            &:active {
                background-color: $navy;
                color: $white;
            }
        }
    }
}

footer {
    padding: 5px;
    background-color: $gray-100;
    font-size: $size-sm - 2;
    position: fixed;
    bottom: 0;
    width: 100%;

    p {
        margin: 0;
    }

    .policy-links {
        a {
            color: $black;
            text-decoration: none;
        }
    }
}

.contact-person-popover {
    max-width: 350px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-family: 'Mulish', sans-serif !important;

    .header {
        background-color: $gray-200;
        color: $black;
        margin-bottom: 0;
        padding: 8px 16px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.20);
        background: #F0F0F0;
    }

    .body {
        padding: 16px;
        font-family: 'Mulish', sans-serif !important;

        .name {
            color: $black;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        .email {
            a {
                color: $black;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                text-decoration: none;
            }
        }

        .phone-no {
            color: $black;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
}