.primary-table {

  table-layout: auto !important;

  thead {
    position: sticky;
    top: 0;
  }

  th {
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  tr {
    color: $black !important;
    font-size: 16px !important;
    font-style: normal;
    line-height: 24px;

  }
}