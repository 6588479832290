@import "./variables.css";

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 12px;
}

html,
body,
#root {
    height: 100%;
}

.container {
    height: calc(100% - 78px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

    .container.drop-off {
        overflow: auto;
        max-width: 100%;
    }

@media (min-width: 768px) {
    html {
        font-size: 14px;
    }
}

.navbar {
    min-height: 10vh;
    padding: 0px;
    margin-bottom: 0px;
}

.invalid-container {
    background-color: #F0F0F0;
    height: 100%;
}

.invalid-header {
    background-color: #0973ba;
    height: 52px;
    padding: 6px;
}


.btn-authenticate,
.btn-authenticate :hover {
    border-radius: 10px !important;
    margin-top: 10px;
}

.btn-link:focus {
    box-shadow: none;
}

.btn-success:disabled {
    background-color: #88c656;
}

.btn-success:hover {
    background-color: #88c656;
}

.btn-success-fill:active {
    background-color: #88c656;
}

.btn-success-fill:focus {
    box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 30%);
    background-color: #88c656;
}

.btn-success-fill {
    background-color: #88c656;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    width: 100px;
    border: none;
    border-radius: 8px !important;
    box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 30%);
    padding: 8px 25px;
    text-align: center;
}

    .btn-success-fill:hover {
        color: #fff;
    }

.btn-success-outline {
    background-color: #fff;
    color: #88c656;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border: solid 1px #88c656;
    border-radius: 8px !important;
    /* box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 30%); */
    padding: 8px 25px;
}

    .btn-success-outline:hover {
        color: #88c656;
    }



.btn {
    position: relative;
    border: none;
}

    .btn i {
        float: left;
        right: auto;
        left: 0;
        position: absolute;
        top: 0;
        height: 100%;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        padding: 9px 6px;
        font-size: 12px;
        line-height: 1.42857143;
        text-shadow: 0px 1px 0px #ebebeb;
        border-radius: 2px !important;
    }

.btn-icon {
    padding: 9px 12px 10px 34px;
}

.btn-info {
    background-color: #21a9e1;
    color: white;
}

    .btn-info:hover {
        background-color: #1a8fbf;
    }

    .btn-info i {
        background-color: #1a8fbf;
    }

.btn-white {
    background: #fafafa;
    border: 1px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
    margin-bottom: 2px;
}

    .btn-white:hover {
        background: #ebebeb;
    }

    .btn-white i {
        text-shadow: 0px 1px 0px #ebebeb;
    }

.btn-icon-default {
    background: #ebebeb;
    border: 1px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
    margin-bottom: 2px;
}

    .btn-icon-default:hover {
        background: #ebebeb;
    }

    .btn-icon-default i {
        text-shadow: 0px 1px 0px #ebebeb;
    }

.btn-default {
    color: #303641;
    background-color: #f0f0f1;
    border-color: #f0f0f1;
}

    .btn-default i {
        background: #d4d4d4;
        text-shadow: 0px 1px 0px #ebebeb;
    }

.table > :not(:first-child) {
    border-top: none !important;
}

.download-button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

    .download-button:disabled {
        opacity: 0.8;
        cursor: not-allowed;
        pointer-events: auto;
    }

.navbar-brand img {
    width: 180px;
    height: 52px;
    margin-top: 12px;
}

.navbar .container {
    margin-left: 0;
    margin-right: 0;
}

    .navbar .container .separator {
        height: 40px;
        border-right: 1px solid dimgrey;
    }

#company-name {
    font-weight: bold;
    margin-left: 14px;
    min-width: 50%;
}

.mail {
    font-size: 14px;
    color: #88c656;
    font-weight: bold;
}

    .mail a:hover {
        cursor: pointer;
    }

.header-right-container {
    margin-left: auto;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.header-contact-info {
    float: right;
    cursor: pointer;
    margin-left: 0;
    padding: 2px 6px;
}

    .header-contact-info a svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 5px;
    }

    .header-contact-info:hover {
        text-decoration: underline;
    }

.dropoff-layout-container {
    width: 100%;
    height: calc(100vh - 81px);
    display: flex;
    overflow: hidden;
}


/* Search Component */
.search-component-container {
    position: absolute;
    z-index: 10;
    width: 220px;
    height: 35vh;
    border: 1px solid grey;
    background-color: white;
    overflow-y: auto;
    box-shadow: 2px 2px 5px;
}

    .search-component-container .input-group {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    .search-component-container .search-icon {
        margin-right: 5px;
        margin-left: 5px;
    }



.access-code-modal-body-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.access-code-modal-body-container .resend-btn {
    text-decoration: none;
    font-size: 15px;
    color: grey;
    padding: 10px;
}

.access-code-modal-body-container .continue-btn {
    width: 180px;
}

.loading-message {
    text-align: center;
}


input.underlined-input {
    border: none;
    border-bottom: 1px solid #ced4da;
}

    input.underlined-input:focus-visible,
    input.underlined-input:focus,
    .btn.btn-authenticate {
        outline: none;
    }

.btn-authenticate {
    width: 150px !important;
}

/* Access Link Layout */

.access-link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    width: 60%;
    border-radius: 15px;
    margin: 20px auto;
    max-height: calc(100% - 10px);
}
/* end */

@media (min-width: 100px)and (max-width: 375px) {
    .access-link-container {
        height: 100% !important;
    }
}


.icon-disabled {
    cursor: not-allowed !important;
}