@import "./variables";
@import "~bootstrap/scss/bootstrap";
@import "./components/button";
@import "./components/form";
@import "./components/layout";
@import "./components/utilities";
@import "./components/toaster";
@import "./components/alert";
@import "./components/modals";
@import "./components/tables";

html,
body {
    height: 100%;
}

body {
    font-family: $font-family;
    font-size: $size-md;
    margin: 0;
}

/* Input CSS Starts*/

.primary-input {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid $gray-400;
    background: $white;

    &:focus-visible {
        outline: $gray-600;
    }
}

.primary-input.error {
    border-color: $error-red !important;
    border-width: 1px !important;
}

.form-floating {
    label {
        opacity: 0.7;
        font-size: 14px;
    }

    ;
}

.primary-checkbox {
    width: 16px;
    height: 16px;

    &:checked {
        background-color: $sapphire !important;
        border-color: $sapphire !important;
    }

}

/* Input CSS Ends */

/* Disabled Input CSS Starts*/
.disabled {
    cursor: not-allowed !important;
    color: $gray-400;
    opacity: 0.4;
}

/* Disabled Input CSS Ends */

.tox-tinymce {
    height: 38vh !important;
}

/* Step Wizard CSS Starts*/

.step-wizard-container {
    display: flex;

    .step-wizard-icons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;

        .access-step {
            border: 2px solid $sapphire;
            text-align: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.6rem;
            color: $sapphire;
        }

        .disabled-access-step {
            color: $gray-200 !important;
            font-weight: 400 !important;

            .access-step {
                opacity: 0.5;
                border-color: $gray-200;
                color: $gray-200 !important;
            }

            .menu-title {
                color: $black;
                font-weight: 400;
            }

        }

        .menu-title {
            padding-top: 10px;
        }

        .step-border {
            border-bottom: 1px solid $gray-200;
            width: 100%;
            margin: 0px -23px 30px -23px;
        }

        .active-border {
            border-color: $sapphire;
            border-width: 2px;
        }

        .step-wizard-icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0px;
            width: 78px;
            color: $black;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;

            svg {
                margin: -3px;
                width: 2rem;
                height: 2rem;
            }
        }


    }

    /* tablet Css */

    @media (max-width: 768px) {}

    /* mobile Css */

    @media (max-width: 450px) {

        .menu-title {
            display: none;
        }

        .step-wizard-icons {
            padding: 10px 0px;
        }

        .step-border {
            margin: 0px !important;
        }
    }

    @media (min-width: 100px)and (max-width: 375px) {

        .step-wizard-icons {
            width: 100% !important;
            padding: 0 10px !important;
        }
    }
}

.header-step-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    .step-wrapper {
        color: $sapphire-200;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        text-align: center;
        position: relative;

        .disabled-access-step {
            font-weight: 600;
            color: $white;
        }

        &.done {
            .step {
                background-color: $accent;
                border-color: $accent;
            }
        }

        &.absolute {
            .step {
                position: absolute;
                top: -24px;
                left: 2px;
            }

            .step-name {
                position: absolute;
                top: 5px;
                left: -18px;
            }
        }
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 5px;
        height: 32px;
        min-width: 32px;
        border: 2px solid $white;
        color: $white;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;

        &.current {
            background-color: $white;
            color: $sapphire-600;
        }
    }

    .horizontal-line {
        height: 2px;
        width: 100%;
        margin: 0 10px;
        background-color: $gray-200;
        margin-bottom: 25px;
    }
}

.header-2 {
    background-color: $sapphire-600 !important;
    padding: 0px !important;
    border: none !important;

    .header-right-container {
        padding-right: 20px !important;
    }

    .profile-icon {
        background-color: $sapphire-600 !important;

    }
}

// Mobile Step header Wizard CSS Start
.header-step-container {
    width: 100%;
    padding: 0 10px;

    .step {
        display: flex;
        min-width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 90px;
        text-align: center;
        font-family: Mulish;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 2px;
    }

    .horizontal-line {
        width: 40px;
        margin: 0 5px;
        margin-bottom: 25px;
    }

    .step-wrapper {
        &.done {
            .step {
                border: none;
                padding: 0px;
            }
        }

        &.absolute {
            .step {
                position: absolute;
                top: -20px;
                left: 2px;
            }

            .step-name {
                top: 2px;

            }

        }

        &.current {
            .step {
                background-color: $white;
                color: $sapphire-600;
            }

            .step-name {
                color: $white;
                text-align: center;
                font-family: Mulish;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                /* 15px */
            }
        }
    }
}

// Mobile Step header Wizard CSS End